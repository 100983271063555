/* eslint-disable camelcase */
import Cookies from "universal-cookie";

const refreshTokenName = process.env.REACT_APP_ENV ?
  `${process.env.REACT_APP_ENV}_refresh_token` :
  "refresh_token"
const accessTokenName = process.env.REACT_APP_ENV ?
  `${process.env.REACT_APP_ENV}_access_token` :
  "access_token"


export const isAuthed = () => {
  const cookies = new Cookies();
  return cookies.get(refreshTokenName) !== null;
};

export const dropDb = () => {
  const cookies = new Cookies();
  cookies.remove(refreshTokenName, { path: "/" });
  cookies.remove(accessTokenName, { path: "/" });
};

export const writeItemToDb = (key, value) => {
  const cookies = new Cookies();
  cookies.set(key, value, { path: "/" });
};

export const getRefreshToken = () => {
  const cookies = new Cookies();
  return cookies.get(refreshTokenName);
};

export const getRefreshTokenFromDb = () => {
  const cookies = new Cookies();
  return cookies.get(refreshTokenName);
};

export const getAccessTokenFromDb = () => {
  const cookies = new Cookies();
  return cookies.get(accessTokenName);
};

export const writeUserCredentialsToDb = (access_token, refresh_token) => {
  if (refresh_token !== null) writeItemToDb(refreshTokenName, refresh_token);
  writeItemToDb(accessTokenName, access_token);
};

export const getItemFromDb = key => {
  const cookies = new Cookies();
  return cookies.get(key);
};
