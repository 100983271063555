export const USERLOCATION_ERROR = 'USERLOCATION_ERROR'
export const errorByFetchingGeolocation = err => ({
  type: USERLOCATION_ERROR,
  errorCode: err.code,
  errorMessage: err.message
})

export const USERLOCATION_FETCHED = 'USERLOCATION_FETCHED'
const userGeolocation = pos => {
  const crd = pos.coords
  const currentLocation = {
    lat: Number(crd.latitude),
    lng: Number(crd.longitude)
  }
  return {
    type: USERLOCATION_FETCHED,
    currentLocation
  }
}


export const setUserGeolocation = position => dispatch => (
  dispatch(userGeolocation(position))
)