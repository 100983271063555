import { loginUserWith } from "../../../util/api";
import { writeUserCredentialsToDb } from "../../../util/db";
import authenticate from "../../authentication";
import { startSession } from "../../session";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
const loginRequest = () => ({
  type: LOGIN_REQUEST,
  isLoading: true
});

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
const loginSuccess = (accessToken, refreshToken) => ({
  type: LOGIN_SUCCESS,
  accessToken,
  refreshToken,
  isLoading: false,
  isSuccess: true
});

export const LOGIN_FAILURE = "LOGIN_FAILURE";
const loginFailure = msg => ({
  type: LOGIN_FAILURE,
  msg,
  isLoading: false,
  isSuccess: false
});

const handleSuccesfullAttempt = (accessToken, refreshToken, dispatch) => {
  startSession(refreshToken, accessToken, dispatch);
  writeUserCredentialsToDb(accessToken, refreshToken);
  dispatch(loginSuccess(accessToken, refreshToken));

  setTimeout(() => {
    dispatch(authenticate(true));
  }, 750);
};

const handleFailedAttempt = (errMsg, dispatch) => {
  dispatch(loginFailure(errMsg));
};

export const doLoginWith = (username, password) => dispatch => {
  // First dispatch: the app state is updated to inform
  // that the API call is starting.
  dispatch(loginRequest());

  // The function called by the thunk middleware can return a value,
  // that is passed on as the return value of the dispatch method.
  // In this case, we return a promise to wait for.
  // This is not required by thunk middleware, but it is convenient for us.
  loginUserWith(username, password)
    .then(res => {
      const { accessToken, refreshToken } = res;
      setTimeout(
        () => handleSuccesfullAttempt(accessToken, refreshToken, dispatch),
        750
      );
    })
    .catch(err => {
      if (err.response) {
        handleFailedAttempt(err.response.data.msg, dispatch);
      } else {
        handleFailedAttempt("Something went wrong! 😱", dispatch);
      }
    });
};
