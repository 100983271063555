/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* 
######################################################
        API
######################################################
*/
import axios from "axios";
import { getAccessTokenFromDb, getRefreshTokenFromDb } from "./db";
import Raven from "./raven";

// ########## Settings ##########
const api = axios.create({
  // eslint-disable-next-line no-underscore-dangle
  baseURL: `${process.env.REACT_APP_API_URL}api`,
});

const authHeaderWith = (token) => ({
  headers: { Authorization: `Bearer ${token}` },
});

const authHeaderWithAccessToken = () => {
  const accessToken = getAccessTokenFromDb();
  return authHeaderWith(accessToken);
};

const handleRequestError = (error) => {
  try {
    const defaultMSG = "Something went wrong! 😱";
    let messages = defaultMSG;

    if (error.response) {
      messages = error.response.data.msg ? error.response.data.msg : defaultMSG;

      messages = error.response.data.error
        ? error.response.data.error
        : defaultMSG;
    }

    const err = new Error(messages);
    return err;
  } catch (catchError) {
    return new Error("Something went wrong! 😱");
  }
};

// eslint-disable-next-line no-unused-vars
const errorHandler = (error) => {
  Raven.captureException(error);
};

// ########## Endpoints ##########
// ---------- Auth ----------
export async function loginUserWith(username, password) {
  const response = await api.post("login", { username, password });
  const {
    data: { access_token: accessToken, refresh_token: refreshToken },
  } = response;
  return { accessToken, refreshToken };
}

export async function registerUser(email, name, username, password) {
  const response = await api.post("register", {
    email,
    name,
    username,
    password,
  });
  const {
    data: { access_token: accessToken, refresh_token: refreshToken },
  } = await response;
  return { accessToken, refreshToken };
}

export async function reauthWith(refreshToken) {
  const response = await api.get("token/refresh", authHeaderWith(refreshToken));
  const {
    data: { access_token: accessToken },
  } = await response;
  return { accessToken };
}

export async function logoutUser() {
  const refreshToken = getRefreshTokenFromDb();

  const revokeAccessToken = () =>
    api.post("logout/access", null, authHeaderWithAccessToken());
  const revokeRefreshToken = () =>
    api.post("logout/refresh", null, authHeaderWith(refreshToken));

  await axios.all([revokeAccessToken(), revokeRefreshToken()]);
}

// ---------- Customer ----------
export const getCustomerById = async (cid) => {
  const customer = await api
    .get(`customer/${cid}`, authHeaderWithAccessToken())
    .catch((error) => {
      const err = handleRequestError(error);
      throw err;
    });
  return customer;
};

export const getCustomercVisits = async (cid) => {
  const visits = await api
    .get(`customer/${cid}/visits`, authHeaderWithAccessToken())
    .catch((error) => {
      const err = handleRequestError(error);
      throw err;
    });
  return visits;
};

export const getCustomerVisitsTimeInformation = async (cid) => {
  const visits = await api
    .get(`customer/${cid}/visits?last=1&next=1`, authHeaderWithAccessToken())
    .catch((error) => {
      const err = handleRequestError(error);
      throw err;
    });
  return visits;
};

export const createCustomer = async (customer) => {
  const newCustomer = await api
    .post(`customer`, customer, authHeaderWithAccessToken())
    .then((res) => res)
    .catch((error) => {
      const err = handleRequestError(error);
      throw err;
    });
  return newCustomer;
};

export const registerPropect = async (_id, query) => {
  const newCustomer = await api
    .post(`register/${_id}`, query, authHeaderWithAccessToken())
    .then((res) => res)
    .catch((error) => {
      const err = handleRequestError(error);
      throw err;
    });
  return newCustomer;
};

export const updateCustomer = async (cid, customer) => {
  const updatedCustomer = await api
    .put(`customer/${cid}`, customer, authHeaderWithAccessToken())
    .catch((error) => {
      const err = handleRequestError(error);
      throw err;
    });
  return updatedCustomer;
};

export const createCustomerContact = async (cid, contact) => {
  const customerContact = await api
    .post(`customer/${cid}/contact`, contact, authHeaderWithAccessToken())
    .catch((error) => {
      const err = handleRequestError(error);
      throw err;
    });
  return customerContact;
};

export const updateCustomerContact = async (cid, conid, contact) => {
  const updatedCustomerContact = await api
    .put(
      `customer/${cid}/contact/${conid}`,
      contact,
      authHeaderWithAccessToken()
    )
    .catch((error) => {
      const err = handleRequestError(error);
      throw err;
    });
  return updatedCustomerContact;
};

export const createCustomerProduct = async (cid, product) => {
  const customerProduct = await api
    .post(`customer/${cid}/product`, product, authHeaderWithAccessToken())
    .catch((error) => {
      const err = handleRequestError(error);
      throw err;
    });
  return customerProduct;
};

export const updateCustomerProduct = async (cid, pid, product) => {
  const updatedCustomerProduct = await api
    .put(`customer/${cid}/product/${pid}`, product, authHeaderWithAccessToken())
    .catch((error) => {
      const err = handleRequestError(error);
      throw err;
    });
  return updatedCustomerProduct;
};

export const deleteCustomerProduct = async (cid, pid) => {
  const deletedCustomerProduct = await api
    .delete(`customer/${cid}/product/${pid}`, authHeaderWithAccessToken())
    .catch((error) => {
      const err = handleRequestError(error);
      throw err;
    });
  return deletedCustomerProduct;
};

export const deleteCustomerContact = async (cid, conid) => {
  const deletedCustomerContact = await api
    .delete(`customer/${cid}/contact/${conid}`, authHeaderWithAccessToken())
    .catch((error) => {
      const err = handleRequestError(error);
      throw err;
    });
  return deletedCustomerContact;
};

// ---------- Appointments ----------

export const getUpcomingAppointments = async ({start, end}) => {
  let query = 'upcoming=1'
  if (start && end) {
    query = `start=${start}&end=${end}` 
  }

  const upcomingAppointments = await api
    .get(`appointments?${query}`, authHeaderWithAccessToken())
    .catch((error) => {
      if (error.response) return error.response;
      return { status: 502, data: { msg: "Something went wrong" } };
    });
  return upcomingAppointments;
};

export const getUpcomingDownload = async () => {
  const upcomingAppointments = await api
    .get(`download/upcoming`, authHeaderWithAccessToken())
    .catch((error) => {
      if (error.response) return error.response;
      return { status: 502, data: { msg: "Something went wrong" } };
    });
  return upcomingAppointments;
};

export const createNewAppointment = async (appointment) => {
  const newAppointment = await api
    .post(`appointment`, appointment, authHeaderWithAccessToken())
    .catch((error) => {
      if (error.response) return error.response;
      return { status: 502, data: { msg: "Something went wrong" } };
    });

  return newAppointment;
};

export const createNewAppointments = async (appointments) => {
  const newAppointment = await api
    .post(`appointments`, appointments, authHeaderWithAccessToken())
    .catch((error) => {
      if (error.response) return error.response;
      return { status: 502, data: { msg: "Something went wrong" } };
    });

  return newAppointment;
};

export const updateAppointment = async (aid, appointment) => {
  const updatedAppointment = await api
    .put(`appointment/${aid}`, appointment, authHeaderWithAccessToken())
    .catch((error) => {
      if (error.response) return error.response;
      return { status: 502, data: { msg: "Something went wrong" } };
    });

  return updatedAppointment;
};

export const deleteAppointment = async (aid) => {
  const updatedAppointment = await api
    .delete(`appointment/${aid}`, authHeaderWithAccessToken())
    .catch((error) => {
      const { status, data } = error.response;
      return { status, data, error: data.msg || data.error };
    });
  return updatedAppointment;
};

// ---------- Weekly ----------
export const getWeeklyCSV = async (start, end) => {
  const weeklyCSV = await api
    .get(
      `download/appointments?start=${start}&end=${end}`,
      authHeaderWithAccessToken()
    )
    .catch((error) => {
      const err = handleRequestError(error);
      throw err;
    });
  return weeklyCSV;
};

export const getWeeklyAppointments = async (start, end) => {
  const weeklyCSV = await api
    .get(`appointments?start=${start}&end=${end}`, authHeaderWithAccessToken())
    .catch((error) => {
      return {
        data: [],
        error: error.response,
      };
    });
  return weeklyCSV;
};

export const getAddressFromGeoCoordination = async (data) => {
  const address = await api
    .post(`coordinates`, data, authHeaderWithAccessToken())
    .catch((error) => {
      const err = handleRequestError(error);
      throw err;
    });
  return address;
};

// ---------- UI ----------

export const getManagers = async () => {
  const managers = await api
    .get(`ui/manager`, authHeaderWithAccessToken())
    .then((res) => res)
    .catch((error) => {
      const err = handleRequestError(error);
      throw err;
    });
  return managers;
};

const compareCountrys = (a, b) => {
  const flagA = a.name.toUpperCase();
  const flagB = b.name.toUpperCase();

  let comparison = 0;
  if (flagA > flagB) {
    comparison = 1;
  } else if (flagA < flagB) {
    comparison = -1;
  }
  return comparison;
};

export const getCountrys = async () => {
  const countrys = await api
    .get(`ui/country`, authHeaderWithAccessToken())
    .then((res) => res)
    .catch((error) => {
      const err = handleRequestError(error);
      throw err;
    });
  countrys.data.sort(compareCountrys);

  return countrys;
};

export const getTrays = async () => {
  const trays = await api
    .get(`ui/trays`, authHeaderWithAccessToken())
    .then((res) => res)
    .catch((error) => {
      const err = handleRequestError(error);
      throw err;
    });
  return trays;
};


export const updateDebitorInformation = async actor_id => {
  const debitor = await api
    .get(`debitor/${actor_id}`, authHeaderWithAccessToken())
    .then((res) => res)
    .catch((error) => {
      const err = handleRequestError(error);
      throw err;
    });
  return debitor;
};

export const checkRefresh = async refreshToken => {
  const check = await api
    .get(`validate_token_r`, authHeaderWith(refreshToken))
    .then((res) => res)
    .catch((error) => {
      const err = handleRequestError(error);
      throw err;
    });
  return check;
};
