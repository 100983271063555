import { reauthWith } from "../../util/api";
import {
  writeUserCredentialsToDb,
  getRefreshTokenFromDb,
  getAccessTokenFromDb
} from "../../util/db";

let sessionTimer;

const startTimer = (refreshToken, accessToken, dispatch) => {
  clearTimeout(sessionTimer);
  if (refreshToken === null) refreshToken = getRefreshTokenFromDb();
  if (accessToken === null) accessToken = getAccessTokenFromDb();

  // eslint-disable-next-line no-use-before-define
  sessionTimer = setTimeout(() => doSessionRefresh(refreshToken, dispatch), 840000);
};

const endTimer = () => clearTimeout(sessionTimer);

export const SESSION_STARTED = "SESSION_STARTED";
const startingSession = () => ({
  type: SESSION_STARTED
});

export const SESSION_REFRESHED = "SESSION_REFRESHED";
const refreshSession = (accessToken) => ({
  type: SESSION_REFRESHED,
  accessToken
});

export const SESSION_END = "SESSION_END";
const endingSession = () => ({
  type: SESSION_END
});

const restartSessionTimer = (refreshToken, dispatch) => startTimer(refreshToken, null, dispatch);

const doSessionRefresh = (refreshToken, dispatch) => {
  reauthWith(refreshToken).then((res) => {
    const { accessToken } = res;
    writeUserCredentialsToDb(accessToken, null);
    restartSessionTimer(refreshToken, dispatch);
    dispatch(refreshSession(accessToken));
  });
};

export const startSession = (refreshToken, accessToken, dispatch) => {
  startTimer(refreshToken, accessToken, dispatch);
  dispatch(startingSession());
};

export const endSession = (dispatch) => {
  endTimer();
  dispatch(endingSession());
};
