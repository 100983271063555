/* eslint-disable no-underscore-dangle */
// import { logoutUser } from '../../util/api'
import { dropDb } from "../../../util/db";
import authenticate from "../../authentication";
import { endSession } from "../../session";
import { logoutUser } from "../../../util/api";
import { removeCurrentCustomer } from "../../customer";
import { azureLogout } from "../../../util/utils";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
const logoutRequest = () => ({
  type: LOGOUT_REQUEST
});

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
const logoutSuccess = () => ({
  type: LOGOUT_SUCCESS
});

export const LOGOUT_FAILURE = "LOGOUT_FAILURE";
// const logoutFailure = msg => ({
//   type: LOGOUT_FAILURE,
//   msg
// })

// const deconstructError = err => {
//   const { msg } = err
//   return { msg }
// }

const handleSuccessfullAttempt = (dispatch) => {
  dropDb();
  dispatch(authenticate(false));
  dispatch(logoutSuccess());
  endSession(dispatch);
  removeCurrentCustomer(dispatch);
  azureLogout();
};

// const handleFailedAttempt = (err, dispatch) => {
//   const { msg } = deconstructError(err)
//   dispatch(logoutFailure(msg))
// }

export const doLogout = () => (dispatch) => {
  dispatch(logoutRequest());

  logoutUser()
    .then(() => handleSuccessfullAttempt(dispatch))
    .catch((err) => {
      dispatch(authenticate(false));
      dropDb();
      azureLogout();
      return Error(err);
    });
};
