import {
  SET_APPOINTMENT,
  REMOVE_APPOINTMENT
} from "../actions/appointment/appointment";

export default (state = {}, action) => {
  switch (action.type) {
    case SET_APPOINTMENT:
      return {
        ...action.appointment
      };
    case REMOVE_APPOINTMENT: {
      return {
        ...action.payload
      };
    }
    default:
      return state;
  }
};
