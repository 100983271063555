import React from "react";
import PropTypes from "prop-types";
// import loading component from material ui
import { Button, CircularProgress } from "@material-ui/core";
// import Wrapper
import LoaderWrapper from "./Wrapper";

const Cycle = () => <CircularProgress size={50} />;

const RefreshButton = () => (
  <Button
    variant="contained"
    onClick={() => {
      window.location.reload();
    }}
  >
    Retry
  </Button>
);

const Loader = ({ error, retry, pastDelay }) => {
  if (error) {
    return (
      <LoaderWrapper>
        <RefreshButton retry={retry} />
      </LoaderWrapper>
    );
  } else if (pastDelay) {
    return (
      <LoaderWrapper>
        <Cycle />
      </LoaderWrapper>
    );
  }
  return null;
};

RefreshButton.propTypes = {
  retry: PropTypes.func.isRequired
};

Loader.propTypes = {
  error: PropTypes.bool,
  pastDelay: PropTypes.bool,
  retry: PropTypes.func
};

export default Loader;
