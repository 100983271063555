import { SET_CUSTOMER, REMOVE_CUSTOMER } from '../actions/customer'

export default (state = {}, action) => {
  switch (action.type) {
    case SET_CUSTOMER:
      return {
        ...action.customer
      }
    case REMOVE_CUSTOMER: {
      return {
        ...action.payload
      }
    }
    default:
      return state
  }
}
