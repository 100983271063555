export const SET_APPOINTMENT = "SET_APPOINTMENT";
const setAppointment = appointment => ({
  type: SET_APPOINTMENT,
  appointment
});

export const REMOVE_APPOINTMENT = "REMOVE_APPOINTMENT";
const removeAppointment = () => ({
  type: REMOVE_APPOINTMENT,
  payload: {}
});

export const setCurrentAppointment = appointment => dispatch => {
  dispatch(setAppointment(appointment));
};

export const removeCurrentAppointment = dispatch => {
  dispatch(removeAppointment());
};
