import { SET_REPORTS, REMOVE_REPORTS } from '../actions/customer/reports'

export default (state = {}, action) => {
  switch (action.type) {
    case SET_REPORTS:
      return {
        ...action.reports
      }
    case REMOVE_REPORTS: {
      return {
        ...action.payload
      }
    }
    default:
      return state
  }
}
