import React from 'react'
import PropTypes from 'prop-types'
// import loading component from material ui
import { Grid } from '@material-ui/core'

const LoadingWrapper = ({ children }) => (
  <Grid
    container
    spacing={1}
    alignContent="center"
    alignItems="center"
    justify="center"
    direction="row"
  >
    <Grid item style={{ paddingTop: '168px' }}>
      {children}
    </Grid>
  </Grid>
)

LoadingWrapper.propTypes = {
  children: PropTypes.object
}

export default LoadingWrapper
