export const SET_REPORTS = 'SET_REPORTS'
const setReports = customerReports => ({
  type: SET_REPORTS,
  reports: [...customerReports]
})

export const REMOVE_REPORTS = 'REMOVE_REPORTS'
const removeReports = () => ({
  type: REMOVE_REPORTS,
  payload: {}
})

export const setCurrentReports = reports => dispatch => {
  dispatch(setReports(reports))
}

export const removeCurrentReports = dispatch => {
  dispatch(removeReports())
}
