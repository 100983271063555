import { CHANGE_AUTH } from '../actions/authentication'

export default (state = false, action) => {
  switch (action.type) {
    case CHANGE_AUTH:
      return action.isLoggedIn
    default:
      return state
  }
}
