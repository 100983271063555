export const SET_CUSTOMER = 'SET_CUSTOMER'
const setCustomer = customer => ({
  type: SET_CUSTOMER,
  customer
})

export const REMOVE_CUSTOMER = 'REMOVE_CUSTOMER'
const removeCustomer = () => ({
  type: REMOVE_CUSTOMER,
  payload: {}
})

export const setCurrentCustomer = customer => dispatch => {
  dispatch(setCustomer(customer))
}

export const removeCurrentCustomer = dispatch => {
  dispatch(removeCustomer())
}
