import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE
} from "../actions/user/login";
import {
  REGISTATION_REQUEST,
  REGISTATION_SUCCESS,
  REGISTATION_FAILURE
} from "../actions/user/register";
import {
  REAUTH_REQUEST,
  REAUTH_SUCCESS,
  REAUTH_FAILURE
} from "../actions/user/reauth";
import {
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE
} from "../actions/user/logout";
import {
  SESSION_STARTED,
  SESSION_END,
  SESSION_REFRESHED
} from "../actions/session";
import {
  USERLOCATION_FETCHED,
  USERLOCATION_ERROR
} from "../actions/user/geolocation";
import {
  FETCHING_ONGOING_APPOINTMENTS,
  ONGOING_APPOINTMETS_FETCHED,
  FAILURE_BY_FETCHING_ONGOING_APPOINTMENTS
} from "../actions/user/appointments/ongoing";

export default (
  state = {
    isLoading: false,
    isSuccess: false,
    hasError: {},
    appointments: {},
    weekly: { visits: [] }
  },
  action
) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isLoading: action.isLoading
      };
    case REGISTATION_REQUEST:
      return {
        ...state,
        isLoading: action.isLoading
      };
    case REAUTH_REQUEST:
      return state;
    case LOGOUT_REQUEST:
      return state;
    case FETCHING_ONGOING_APPOINTMENTS:
      return {
        ...state,
        isLoading: action.isLoading,
        appointments: action.appointments
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        hasError: {},
        isLoading: action.isLoading,
        isSuccess: action.isSuccess,
        accessToken: action.accessToken,
        refreshToken: action.refreshToken
      };
    case REGISTATION_SUCCESS:
      return {
        ...state,
        hasError: {},
        isLoading: action.isLoading,
        isSuccess: action.isSuccess,
        accessToken: action.accessToken,
        refreshToken: action.refreshToken
      };
    case REAUTH_SUCCESS:
      return {
        ...state,
        hasError: {},
        accessToken: action.accessToken,
        refreshToken: action.refreshToken
      };
    case LOGOUT_SUCCESS:
      return {
        hasError: {},
        isLoading: false,
        isSuccess: false
      };
    case ONGOING_APPOINTMETS_FETCHED:
      return {
        ...state,
        hasError: {},
        isLoading: action.isLoading,
        isSuccess: action.isSuccess,
        appointments: action.appointments
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        isLoading: action.isLoading,
        isSuccess: action.isSuccess,
        hasError: { msg: action.msg }
      };
    case REGISTATION_FAILURE:
      return {
        ...state,
        isLoading: action.isLoading,
        isSuccess: action.isSuccess,
        hasError: { msg: action.msg }
      };
    case REAUTH_FAILURE:
      return {
        ...state,
        isLoading: action.isLoading,
        isSuccess: action.isSuccess,
        hasError: { msg: action.msg }
      };
    case LOGOUT_FAILURE:
      return {
        ...state,
        isLoading: action.isLoading,
        isSuccess: action.isSuccess,
        hasError: {
          msg: action.error.msg
        }
      };
    case FAILURE_BY_FETCHING_ONGOING_APPOINTMENTS:
      return {
        ...state,
        isLoading: action.isLoading,
        isSuccess: action.isSuccess,
        hasError: {
          msg: action.msg
        }
      };
    case SESSION_STARTED:
      return {
        ...state,
        timeout: action.timeout
      };
    case SESSION_REFRESHED:
      return {
        ...state,
        accessToken: action.accessToken
      };
    case SESSION_END:
      return {
        ...state
      };
    case USERLOCATION_FETCHED:
      return {
        ...state,
        location: action.currentLocation
      };
    case USERLOCATION_ERROR:
      return {
        ...state,
        location: {
          error: {
            code: action.errorCode,
            msg: action.errorMessage
          }
        }
      };
    default:
      return state;
  }
};
