/* eslint-disable import/prefer-default-export */
import { SET_WEEKLY, REMOVE_WEEKLY } from "../actions/weekly/weekly";

export const weeklyReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_WEEKLY:
      return {
        ...action.weekly
      };
    case REMOVE_WEEKLY: {
      return {
        ...action.payload
      };
    }
    default:
      return state;
  }
};
