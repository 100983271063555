import { reauthWith } from "../../../util/api";
import { startSession } from "../../session";
import { writeUserCredentialsToDb } from "../../../util/db";

export const REAUTH_REQUEST = "REAUTH_REQUEST";
const reauthRequest = () => ({
  type: REAUTH_REQUEST
});

export const REAUTH_SUCCESS = "REAUTH_SUCCESS";
const reauthSuccess = (accessToken, refreshToken) => ({
  type: REAUTH_SUCCESS,
  accessToken,
  refreshToken
});

export const REAUTH_FAILURE = "REAUTH_FAILURE";
const reauthFailure = (msg) => ({
  type: REAUTH_FAILURE,
  msg
});

const handleSuccesfullReauthentication = (accessToken, refreshToken, dispatch) => {
  startSession(refreshToken, accessToken, dispatch);
  writeUserCredentialsToDb(accessToken, null);
  dispatch(reauthSuccess(accessToken, refreshToken));
};

const handleFailedReauthenticationAttempt = (errMsg, dispatch) => {
  dispatch(reauthFailure(errMsg));
};

export const doReauthWith = (refreshToken) => (dispatch) => {
  dispatch(reauthRequest());

  reauthWith(refreshToken)
    .then((res) => {
      handleSuccesfullReauthentication(res.accessToken, refreshToken, dispatch);
    })
    .catch((err) => {
      if (err.response && err.response.data && err.response.msg) {
        handleFailedReauthenticationAttempt(err.response.data.msg, dispatch);
      } else {
        handleFailedReauthenticationAttempt(err, dispatch);
      }
    });
};
