export const CHANGE_AUTH = 'CHANGE_AUTH'

const authenticate = isLoggedIn => {
  return {
    type: CHANGE_AUTH,
    isLoggedIn
  }
}

export default authenticate
