/* eslint-disable no-underscore-dangle */
export const generateGmapLink = (address) => {
  const { str, str_no: strNo, place, plz } = address;
  const defaultMapLink = "https://www.google.de/maps/dir//";
  const parsedLocationString = `${str}+${strNo}+${plz}+${place}`;
  return `${defaultMapLink}${parsedLocationString}`;
};

const isEmpty = (str) => {
  return !str || str.length === 0 || !str.trim();
};

export const isContactEmpty = (contact) => {
  if (!isEmpty(contact.name)) return false;
  if (!isEmpty(contact.mail)) return false;
  if (!isEmpty(contact.func)) return false;
  if (!isEmpty(contact.tel)) return false;
  if (!isEmpty(contact.mobil)) return false;
  if (!isEmpty(contact.fax)) return false;

  return true;
};

export const isAddressEmpty = (address) => {
  if (!isEmpty(address.str)) return false;
  if (!isEmpty(address.str_no)) return false;
  if (!isEmpty(address.plz)) return false;
  if (!isEmpty(address.place)) return false;
  if (!isEmpty(address.country)) return false;

  return true;
};

export const getCurrentPosition = (options = {}) => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, options);
  });
};

const azureLink = () => {
  if (process.env.REACT_APP_DEV) {
    return `${process.env.REACT_APP_API_URL}azure/`;
  }
  return `${process.env.REACT_APP_API_URL}`;
};

export const azureLogin = () => {
  window.location.href = `${azureLink()}login`;
};

export const azureLogout = () => {
  window.location.href = `${azureLink()}logout`;
};
