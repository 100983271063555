import { getUpcomingAppointments } from "../../../util/api";

export const FETCHING_ONGOING_APPOINTMENTS = "FETCHING_ONGOING_APPOINTMENTS";
const fetchingOngoingAppointments = () => ({
  type: FETCHING_ONGOING_APPOINTMENTS,
  isLoading: true,
  appointments: {
    upcoming: []
  }
});

export const ONGOING_APPOINTMETS_FETCHED = "ONGOING_APPOINTMETS_FETCHED";
const fetchedOngoingAppointments = ongoingAppointments => ({
  type: ONGOING_APPOINTMETS_FETCHED,
  appointments: {
    upcoming: ongoingAppointments
  },
  isLoading: false,
  isSuccess: true
});

export const FAILURE_BY_FETCHING_ONGOING_APPOINTMENTS =
  "FAILURE_BY_FETCHING_ONGOING_APPOINTMENTS";
const failureByFetchingOngoingAppointments = msg => ({
  type: FAILURE_BY_FETCHING_ONGOING_APPOINTMENTS,
  msg,
  isLoading: false,
  isSuccess: false
});

const handleSuccesfullAttempt = (ongoingAppointments, dispatch) => {
  dispatch(fetchedOngoingAppointments(ongoingAppointments));
};

const handleFailedAttempt = (errMsg, dispatch) => {
  dispatch(failureByFetchingOngoingAppointments(errMsg));
};

export const fetchUpcomingAppointments = reqParams => dispatch => {
  // First dispatch: the app state is updated to inform
  // that the API call is starting.
  dispatch(fetchingOngoingAppointments());

  // The function called by the thunk middleware can return a value,
  // that is passed on as the return value of the dispatch method.
  // In this case, we return a promise to wait for.
  // This is not required by thunk middleware, but it is convenient for us.
  getUpcomingAppointments(reqParams)
    .then(ongoingAppointments => {
      handleSuccesfullAttempt(ongoingAppointments, dispatch);
    })
    .catch(err => handleFailedAttempt(err.response.data.msg, dispatch));
};
