/* eslint-disable no-underscore-dangle */
import React from "react";
// Load React specific components
import { render } from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
// import loadable
import Loadable from "react-loadable";
import { LastLocationProvider } from 'react-router-last-location';
// import loadingCycle component
import Loader from "./components/loading/Loader";
// import registerServiceWorker from './util/registerServiceWorker'
import reducers from "./reducers/";
// import ErrorBoundary for error handling an reporting
import ErrorBoundary from "./components/error/ErrorBoundary";
// eslint-disable-next-line import/first
import "./semantic-ui-flag/flag.min.css";

const store = createStore(reducers, composeWithDevTools(applyMiddleware(thunkMiddleware)));

const App = Loadable({
  loader: () => import("./App"),
  loading: Loader,
  delay: 300
});

render(
  <Provider store={store}>
    <Router basename={process.env.REACT_APP_SUBPATH || ""}>
      <LastLocationProvider>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </LastLocationProvider>
    </Router>
  </Provider>,
  document.getElementById("root")
);

// registerServiceWorker()
