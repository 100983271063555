import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Snackbar } from '@material-ui/core'
import Raven from '../../util/raven'

class ErrorBoundary extends Component {
  state = {
    error: null,
    open: false
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, open: true })
    Raven.captureException(error, { extra: errorInfo })
  }

  handleClose = () => this.setState({ open: false })

  render() {
    const { error, open } = this.state
    const message = (
      <span id="error-id">
        Ooops, there happend somthing bad. Don`t worry, it already got reported
      </span>
    )

    if (error) {
      return (
        <Fragment>
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={open}
            autoHideDuration={2500}
            onClose={this.handleClose}
            ContentProps={{
              'aria-describedby': 'error-id'
            }}
            message={message}
          />
          {this.props.children}
        </Fragment>
      )
    }
    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.object.isRequired
}

export default ErrorBoundary
