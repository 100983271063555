import { combineReducers } from "redux";
import authenticationReducer from "./authentication";
import customerReducer from "./customer";
import userManagementReducer from "./user-management";

import visitsReports from "./reports";
import appointmentReducer from "./appointment";
import { weeklyReducer } from "./weekly";

const rootReducer = combineReducers({
  authenticated: authenticationReducer,
  customer: customerReducer,
  reports: visitsReports,
  user: userManagementReducer,
  appointment: appointmentReducer,
  weekly: weeklyReducer
});

export default rootReducer;
