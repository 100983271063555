export const SET_WEEKLY = "SET_WEEKLY";
const setWeekly = (appointments, settings) => ({
  type: SET_WEEKLY,
  weekly: {
    settings,
    appointments
  }
});

export const REMOVE_WEEKLY = "REMOVE_WEEKLY";
const removeWeekly = () => ({
  type: REMOVE_WEEKLY,
  payload: {}
});

export const setCurrentWeekly = (appointments, settings) => dispatch => {
  dispatch(setWeekly(appointments, settings));
};

export const removeCurrentWeekly = dispatch => {
  dispatch(removeWeekly());
};
